/* eslint-disable no-param-reassign */
import digitalData from 'digital-data';

let override = null;

// ---------- from thd-analytics-utils  ------------------------------------------------------------ //

const EVENTS = {
  TOOL_RENTAL_CONFIRMATION: 'toolrental-confirmation',
  PAGE_LOAD_ERROR: 'page-load-error',
  CREATE_ACCOUNT_AT_CHECKOUT_SUCCESS: "create-account-at-checkout-success",
};

export const setAnalytics = () => {
  const _ddo = digitalData.init();

  if (navigator?.permissions?.query) {
    try {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        _ddo.user = {
          ..._ddo.user,
          localization: {
            ..._ddo?.user?.localization,
            gpsPermission: result?.state?.length ? 'gps access ' + result?.state : null
          }
        };
      });
    } catch (err) {
      console.error(err);
    }
  }

  window._T = window._T || {};

  if (typeof window._T.pageData === 'undefined') {
    window._T.pageData = [];
  }
  window._T.pageData.push(_ddo);
};

// ---------- START OF UTILS FUNCTIONS  ------------------------------------------------------------ //

const publishPageLoadAnalytics = ({ eventObject = {} }) => {
  try {
    digitalData.page = eventObject?.page || {};
    digitalData.site = eventObject?.site || {};
    digitalData.transaction = eventObject?.transaction || {};

    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    setAnalytics(digitalData);
  } catch (error) {
    console.error('Error in publishPageLoadAnalytics()', error);
  }
};

const publishEvent = ({ eventObject }) => {
  try {
    // if override function exists
    if (override && typeof override === 'function') {
      override(eventObject);
    }
    digitalData.event.push(eventObject);
  } catch (error) {
    console.error('Error in publishEvent()', error);
  }
};

const setPageInfo = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.pageInfo = {
    abTest: '',
    platform: 'nucleus',
    ...variables
  };
};

const setCategory = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.category = {
    primaryCategory: 'order confirmation',
    subCategory1: '',
    subCategory2: '',
    ...variables
  };
};

const setForm = (eventObject, variables = {}) => {
  eventObject.page = {
    ...eventObject.page
  };
  eventObject.page.form = {
    rentalLeadID: variables?.orderId || ''
  };
};

// ---------- END OF UTILS FUNCTIONS ------------------------------------------------------------ //

const getProductName = (item) => {
  if (item?.brandName && item?.description) {
    return item?.brandName + ' ' + item?.description;
  }
  return item?.brandName || item?.description || '';
};

const toolRentalConfirmation = ({ orderConfirmation }) => {
  const eventObject = {};
  const itemId = orderConfirmation?.itemModels?.[0]?.itemId || '';
  const toolrentalCategory = 'product rental>' + itemId + '>reserve confirm';
  const orderId = orderConfirmation?.orderModel?.orderId;
  const basePrice = orderConfirmation?.estimateModel?.tools?.[0]?.feeTotal || '';
  const tax = orderConfirmation?.estimateModel?.tools?.[0]?.taxTotal || '';

  setPageInfo(eventObject, {
    abTest: '',
    pageName: toolrentalCategory,
    pageType: 'tool:productRental:reserveConfirm',
    pageLoadEvent: 'product rental confirmation',
  });

  setCategory(eventObject, {
    primaryCategory: toolrentalCategory,
    subCategory1: toolrentalCategory,
    subCategory2: toolrentalCategory
  });

  eventObject.transaction = {
    transactionID: orderId || '',
    price: {
      basePrice: basePrice || 0.00,
      shipping: 0.00,
      bodfsShipping: 0.00,
      tax: tax || 0.00,
      transactionTotal: basePrice + tax || 0.00,
    },
    item: [
      {
        fulfillment: {
          method: orderConfirmation?.itemModels?.[0]?.fulfillmentModel?.fulfillmentMethod || '',
          pickupOption: orderConfirmation?.orderModel?.inStore ? 'in-store' : 'curbside',
          store: orderConfirmation?.pickUpOptionModels?.[0]?.storeId || '',
          zip: ''
        },
        price: {
          basePrice: basePrice || 0.00,
          discountAmount: 0.00,
          discountCode: 0.00,
        },
        productInfo: {
          productName: getProductName(orderConfirmation?.itemModels?.[0]),
          serviceLevel: '',
          sku: orderConfirmation?.itemModels?.[0]?.itemId || '',
        },
        quantity: orderConfirmation?.itemModels?.[0]?.quantity || '',
        subscription: {
          frequency: ''
        }
      }
    ],
    profile: {
      address: {
        line1: orderConfirmation?.paymentInfoModel?.addressLine1 || '',
        line2: orderConfirmation?.paymentInfoModel?.addressLine2 || '',
        city: orderConfirmation?.paymentInfoModel?.city || '',
        stateProvince: orderConfirmation?.paymentInfoModel?.state || '',
        postalCode: orderConfirmation?.paymentInfoModel?.zipCode || '',
        country: orderConfirmation?.paymentInfoModel?.country || ''
      }
    }
  };

  setForm(eventObject, { orderId });
  publishPageLoadAnalytics({ eventObject });
};

const handlePageLoadError = (message, code) => {
  try {
    const eventObject = {
      eventInfo: {
        error: [{
          type: 'error',
          displayType: 'page',
          pageSection: 'reservation confirmation',
          message,
          code
        }]
      }
    };
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

const setEventInfo = (eventObject, variables = {}) => {
  eventObject.eventInfo = {
    ...variables
  };
};

const handleEvent = (primaryCategory, eventName) => {
  try {
    const eventObject = {
      category: {
        primaryCategory
      }
    };
    setEventInfo(eventObject, {
      eventName
    });
    publishEvent({ eventObject });
  } catch (error) {
    console.error(error);
  }
};

export const orderConfirmation = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('order-confirmation-analytics', ({ output = {} }) => {
    try {
      const analyticsEventName = output?.analyticsEventName || '';
      override = output?.override || null;

      switch (analyticsEventName) {
      case EVENTS.TOOL_RENTAL_CONFIRMATION:
        toolRentalConfirmation(output);
        break;
      case EVENTS.PAGE_LOAD_ERROR:
        handlePageLoadError(output?.message, output?.code);
        break;
      case EVENTS.CREATE_ACCOUNT_AT_CHECKOUT_SUCCESS:
        handleEvent('button submission', 'registration');
        break;
      default:
        console.warn('Unable to find order confirmation analytics event of \'' + analyticsEventName + '\'');
        break;
      }
    } catch (error) {
      console.error('Error in order confirmation analytics', error);
    }
  });
};