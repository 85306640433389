import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { STASH, NOT_APPLICABLE, BIP_PAGE, SHOPPABLE_IMAGES_DRAWER } from '../constants';

const eventOriginatedFromProductPodOrFbt = (path) => {
  return (path || [])
    .reverse()
    .find((name) => (
      name.match(/ProductPodDefaultMobile\[/i)
      || name.match(/ProductPodDefaultDesktop\[/i)
      || name.match(/ProductPodDesktop\[/i)
      || name.match(/ProductPodMobile\[/i)
      || name.match(/productpod\[/i)
      || name.match(/frequentlyboughttogether\[/i)));
};

const eventOriginatedFromPromotionProducts = (path) => {
  return !!(path || []).find((name) => name.match(/promotionproducts/i));
};

const isFromPackagePageOrPackageMini = (path) => {
  const packagePageRE = /packagepage/i;
  const packageMiniRE = /packagesmini/i;
  const isFromPackages = path.find((part = '') => (packagePageRE.test(part) || packageMiniRE.test(part)));
  return !!isFromPackages;
};

export const getSectionFromPath = ({ path, cartOptions }) => {
  const buyBoxRE = /buybox/i;
  const sampleBoxRE = /sample/i;
  const isFromBuyBox = (path || []).find((part = '') => buyBoxRE.test(part));
  const isSampleBox = (path || []).find((part = '') => sampleBoxRE.test(part));
  const isFromPackages = isFromPackagePageOrPackageMini(path);

  if (eventOriginatedFromPromotionProducts(path)) {
    return 'module';
  }
  if (isFromBuyBox) {
    return 'buybox';
  }
  if (isSampleBox) {
    return 'sample';
  }
  if (isFromPackages) {
    return cartOptions?.packageInfo?.packageName || 'n/a';
  }
  if (cartOptions?.component === SHOPPABLE_IMAGES_DRAWER) {
    return 'n/a';
  }
  return 'unknown';
};

export const getTargetFromPath = ({ path }) => {
  const lowerCasePath = path.map((name) => (name || '').toLowerCase());
  const atcIndex = lowerCasePath.findIndex((part) => /^addtocart/i.test(part));
  return atcIndex > -1 ? 'atc' : '';
};

const getItemsFromCartReqParams = (cartReqParams, cartOptions, path) => {
  const state = getState();
  const recommendation = {
    recommendationType: cartOptions?.recommendationType || cartOptions?.sharedSection || NOT_APPLICABLE,
    pageType: state?.pageType,
    displayPosition: NOT_APPLICABLE,
    strategy: NOT_APPLICABLE,
    recommendationVersion: NOT_APPLICABLE,
    featureVersion: NOT_APPLICABLE,
    anchorProductSku: cartOptions?.anchorProductSku || NOT_APPLICABLE,
  };
  const isFromPackages = isFromPackagePageOrPackageMini(path);

  if (Array.isArray(cartReqParams)) {
    if (isFromPackages) {
      return cartReqParams.map(({ itemId, displayPosition = NOT_APPLICABLE }) => (
        {
          productInfo: {
            sku: itemId,
          },
          displayPosition,
          recommendation
        }
      ));
    }
    return cartReqParams.map(({ itemId, displayPosition }) => (
      {
        productInfo: {
          sku: itemId,
        },
        displayPosition,
      }
    ));
  }
  return cartOptions?.component === SHOPPABLE_IMAGES_DRAWER
    ? [{ productInfo: { sku: cartReqParams?.itemId }, index: 'n/a' }]
    : [{ productID: cartReqParams?.itemId, index: '' }];
};

export const addToCartAnalytics = () => {
  // @todo logic for dpd atc click
  // https://github.homedepot.com/thd-olt-component/hdhome-analytics/blob/master/events/dpd.js#L35

  window.LIFE_CYCLE_EVENT_BUS.on('cart.add-to-cart', ({ output }) => {
    const {
      cartReqParams,
      cartOptions,
      path = []
    } = output;

    if (eventOriginatedFromProductPodOrFbt(path) && !eventOriginatedFromPromotionProducts(path)) return;

    const { pageType = '' } = getState() || {};

    digitalData.event = digitalData.event || [];
    if (pageType !== 'browse') {
      const section = getSectionFromPath({ path, cartOptions });
      const target = getTargetFromPath({ path });
      const items = getItemsFromCartReqParams(cartReqParams, cartOptions, path);
      const isSample = cartOptions?.section === 'order sample';
      const isBipPage = pageType === BIP_PAGE;
      const sharedSection = cartOptions?.sharedSection || 'n/a';
      const component = cartOptions?.component || '';
      const eventName = component === SHOPPABLE_IMAGES_DRAWER || (Array.isArray(cartReqParams) && !isSample && !isBipPage)
        ? 'cart addition info'
        : 'cart add location';

      const ddoStashEvent = {
        eventInfo: {
          eventName
        },
        category: {
          primaryCategory: STASH
        },
        eventMode: STASH,
        stash: {
          component,
          item: items,
          pageType,
          section,
          sharedSection,
          target
        }
      };
      digitalData.event.push(ddoStashEvent);
    }
  });
};

export const quickAddToCartLocation = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('cart.quick-add-location', ({ output }) => {
    const { data } = output;

    const ddoStashEvent = {
      category: {
        primaryCategory: STASH
      },
      eventInfo: {
        eventName: 'cart add location',
      },
      eventMode: STASH,
      stash: {
        component: data.component || 'n/a',
        pageType: data.pageType || 'quick add',
        section: data.section || 'quick add',
        sharedSection: 'n/a',
        target: data.target || 'atc',
      }
    };
    digitalData.event.push(ddoStashEvent);
  });
};

export const quickAddToCartClick = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('cart.quick-add-click', ({ output }) => {
    const { data } = output;

    // handle stash event
    const ddoStashEvent = {
      category: {
        primaryCategory: data.primaryCategory
      },
      eventInfo: {
        eventName: data.eventName,
      },
      cart: {
        cartID: data.cartObjectData?.cartID
      },
      item: data.items || []
    };
    digitalData.event.push(ddoStashEvent);

    // update cart Object
    const cartObjItems = data.cartObjectData?.items || [];
    const cartObj = {
      ...digitalData.cart,
      cartID: data.cartObjectData?.cartID,
      price: data.cartObjectData?.cartTotal,
      uniqueSkuCount: data.cartObjectData?.uniqueSkuCount,
      quantityTotal: data.cartObjectData?.quantityTotal,
    };

    if (digitalData?.cart?.item && Array.isArray(digitalData?.cart?.item)) {
      // Overwrite existing cart object properties and push new item to it
      digitalData.cart = { ...digitalData.cart, ...cartObj };
      digitalData.cart.item.push(...cartObjItems);
      return;
    }
    digitalData.cart = { ...cartObj, item: cartObjItems };
  });
};