import digitalData from 'digital-data';
import {
  BUTTON_SUBMISSION, STASH, MY_ACCOUNT_SETTINGS, ACCOUNT, OVERLAY
} from '../constants';
import {
  setAnalytics
} from '../thd-analytics-utils';

// https://github.com/one-thd/ocm_pd_experience_customer-account-pojob-proallowance
export const proAllowance = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const proAllowanceEvents = {
    load: ({ output }) => {
      const { pageName } = output || {};

      digitalData.page = {
        pageInfo: {
          pageName: `account>pro allowance management>${pageName}`,
          pageType: 'maml: pro allowance',
        },
        category: {
          primaryCategory: ACCOUNT,
          subCategory1: 'account>pro allowance management',
          subCategory2: `account>pro allowance management>${pageName}`,
        },
      };

      setAnalytics(digitalData);
    },

    getStartedButtonClick: () => {
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: 'pro allowance: get started page: get started button'
        },
      };
      trackEvent(DDOEvent);
    },

    getStateButtonClick: ({ output }) => {
      const { action_name } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        eventMode: STASH,
        myAccount: {
          settings: `pro allowance: state link: ${action_name}`
        },
      };

      trackEvent(DDOEvent);
    },

    getAddUserClick: () => {
      const DDOEvent = {
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        eventMode: STASH,
        myAccount: {
          settings: 'pro allowance management: dont see your shopper: add manage users link'
        },
      };

      trackEvent(DDOEvent);
    },

    getCreateFlowClick: ({ output }) => {
      const { actionName } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: `pro allowance management: create flow: ${actionName}`
        },
      };

      trackEvent(DDOEvent);
    },

    getFinishOverlayClick: ({ output }) => {
      const { actionName } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY
        },
        eventInfo: {
          eventName: `pro allowance ${actionName} youre almost finished`
        }
      };

      trackEvent(DDOEvent);
    },

    getCreateFrequency: ({ output }) => {
      const { userType, frequency, amount, expDate } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: `pro allowance management: create pro allowance: create: ${userType}: ${frequency}: ${amount}: ${expDate}`
        }
      };

      trackEvent(DDOEvent);
    },

    getCreateInteractionButtonClick: ({ output }) => {
      const { actionName } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: `pro allowance management: successful pro allowance creation: ${actionName} pro allowance link`
        },
      };

      trackEvent(DDOEvent);
    },

    getPopupOverlayClick: ({ output }) => {
      const { actionName } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: OVERLAY
        },
        eventInfo: {
          eventName: `do you want to ${actionName} this pro allowance`
        }
      };

      trackEvent(DDOEvent);
    },

    getActionOverlayClick: ({ output }) => {
      const { message } = output || {};
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: message
        },
      };

      trackEvent(DDOEvent);
    },

    tableViewRows: ({ output }) => {
      const { target } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION,
        },
        eventInfo: {
          eventName: 'my account settings',
        },
        myAccount: {
          settings: `pro allowance: view: ${target}`,
        },
      };
      trackEvent(DDOEvent);
    },

    getViewPaginationClick: ({ output }) => {
      const { rows, pageNumber } = output;
      const DDOEvent = {
        category: {
          primaryCategory: BUTTON_SUBMISSION
        },
        eventInfo: {
          eventName: MY_ACCOUNT_SETTINGS
        },
        myAccount: {
          settings: `pro allowance: pagination: view ${rows}: page ${pageNumber}`
        },
      };

      trackEvent(DDOEvent);
    }
  };

  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.page-load', proAllowanceEvents.load);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-started', proAllowanceEvents.getStartedButtonClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-state', proAllowanceEvents.getStateButtonClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.add-user-click', proAllowanceEvents.getAddUserClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.create-flow', proAllowanceEvents.getCreateFlowClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.finish-overlay', proAllowanceEvents.getFinishOverlayClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-create-frequency', proAllowanceEvents.getCreateFrequency);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-interaction-click', proAllowanceEvents.getCreateInteractionButtonClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-popoverlay-click', proAllowanceEvents.getPopupOverlayClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-action-overlay-click', proAllowanceEvents.getActionOverlayClick);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.table-view-rows', proAllowanceEvents.tableViewRows);
  window.LIFE_CYCLE_EVENT_BUS.on('pro-allowance.get-view-pagination-click', proAllowanceEvents.getViewPaginationClick);
};
