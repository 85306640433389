/* eslint-disable max-len */
import digitalData from 'digital-data';
import { ERROR } from '../constants';

export const homeDepotWallet = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const actions = {
    postPageErrors: ({ output }) => {
      const {
        type, displayType, pageSection, message, code = 'n/a'
      } = output;
      const pageErrors = [];
      const errorDetails = {
        type,
        displayType,
        pageSection,
        message,
        code
      };
      pageErrors.push(errorDetails);
      const DDOEvent = {
        category: {
          primaryCategory: ERROR,
        },
        eventInfo: {
          eventName: 'post page error',
          error: pageErrors,
        },
      };
      trackEvent(DDOEvent);
    },

    hdWalletButtonClick: ({ output }) => {
      const { actionType } = output;
      const DDOEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        myAccount: {
          settings: `cards and account: hd pass: authorize card: ${actionType} button`
        }
      };
      trackEvent(DDOEvent);
    },

    removeHdPassButtonClick: ({ output }) => {
      const { buttonType } = output;
      const HDPassEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'my account settings'
        },
        myAccount: {
          settings: `cards and accounts: hd pass: remove authorization: ${buttonType.toLowerCase()} button`
        }
      };
      trackEvent(HDPassEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('home-depot-wallet.post-page-error', actions.postPageErrors);
  window.LIFE_CYCLE_EVENT_BUS.on('home-depot-wallet.actions.button-click', actions.hdWalletButtonClick);
  window.LIFE_CYCLE_EVENT_BUS.on('home-depot-wallet.actions.remove-hd-pass-button-click', actions.removeHdPassButtonClick);
};
